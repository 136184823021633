import React, { useRef } from 'react';
import Select from 'react-select';
import { FaFileImage } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';

const Details = ({
  value,
  options,
  countries,
  handleChange,
  setFieldValue,
  images,
  handlePickImage,
  params,
  removeImage,
  categories,
  health
}) => {
  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  return (
    <div>
      <div className="form">
        <div className="form_content">
          <label>Name</label>
          <input
            type="text"
            placeholder="Name"
            name="name"
            onChange={handleChange}
            value={value.name}
            required
          />
        </div>

        <div className="form_content">
          <label>Description</label>
          <textarea
            type="text"
            placeholder="Description"
            name="description"
            value={value.description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form_content">
          <label>Type</label>
          <div style={{ marginTop: '10px' }} />
          <Select
            name="types"
            isMulti
            options={options}
            value={value.types}
            onChange={(value) => {
              console.log(value);
              setFieldValue('types', value);
            }}
            required
          />
        </div>

        <div className="form_content">
          <label>Category</label>
          <div style={{ marginTop: '10px' }} />
          <Select
            isMulti
            options={categories}
            value={value.categories}
            onChange={(value) => {
              setFieldValue('categories', value);
            }}
            required
          />
        </div>

        <div className="form_content">
          <label>Health Conditions</label>
          <div style={{ marginTop: '10px' }} />
          <Select
            isMulti
            options={health}
            value={value.health_conditions}
            onChange={(value) => {
              setFieldValue('health_conditions', value);
            }}
            required
          />
        </div>

        <div className="form_content">
          <label>Cook Time</label>
          <input
            type="number"
            placeholder="10"
            name="prep_time"
            onChange={handleChange}
            value={value.prep_time}
            required
          />
        </div>

        <div className="form_content">
          <label>Countries</label>
          <div style={{ marginTop: '10px' }} />
          <Select
            name="countries"
            isMulti
            options={countries}
            value={value.countries}
            onChange={(value) => setFieldValue('countries', value)}
            required
          />
        </div>

        <div className="form_content">
          {!images ? (
            <div className="image_label" onClick={handleOpenImage}>
              <FaFileImage size={20} color="#549A9A" />
              <div>
                <p>Upload Image</p>
              </div>
            </div>
          ) : (
            <div
              className="image_preview"
              onClick={params.id ? () => {} : handleOpenImage}
            >
              <div className="delete" onClick={removeImage}>
                <MdDeleteOutline color="#fff" />
              </div>
              <img
                src={
                  typeof images === 'string' ? images : URL.createObjectURL(images)
                }
                alt="meal"
              />
            </div>
          )}
        </div>
        <input
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          ref={imgRef}
          onChange={(e) => handlePickImage(e)}
        />
      </div>
    </div>
  );
};

export default Details;
