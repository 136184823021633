import dashboardIcon from '@assets/icons/dashboard.svg';
import dashboardActive from '@assets/icons/dashboard-active.svg';

import ordersIcon from '@assets/icons/order-history.svg';
import ordersIconActive from '@assets/icons/orderhistory_active.svg';

import usersIcon from '@assets/icons/users.svg';
import usersIconActive from '@assets/icons/users-active.svg';

import groupsIcon from '@assets/icons/groups.svg';
import groupsIconActive from '@assets/icons/groups-active.svg';

import partnersIcon from '@assets/icons/partners.svg';
import partnersActive from '@assets/icons/partners-active.svg';

import recipeIcon from '@assets/icons/recipeIcon.svg';
import recipeIconActive from '@assets/icons/recipeActive.svg';

import {
  DASHBOARDHOME,
  CUSTOMERS,
  GROUPS,
  MAIN_ORDERS,
  RECIPE,
  SETTINGS,
  PARTNERS,
  PRODUCTS_PART
} from '@app/pages/ROUTECONSTS';

export const menuData = [
  {
    icon: dashboardIcon,
    activeIcon: dashboardActive,
    text: 'Dashboard',
    isActive: false,
    link: DASHBOARDHOME
  },
  {
    icon: groupsIcon,
    activeIcon: groupsIconActive,
    text: 'Baskets',
    isActive: false,
    link: GROUPS
  },
  {
    icon: ordersIcon,
    activeIcon: ordersIconActive,
    text: 'Active Orders',
    isActive: false,
    link: MAIN_ORDERS
  },
  {
    icon: usersIcon,
    activeIcon: usersIconActive,
    text: 'Customers',
    isActive: false,
    link: CUSTOMERS
  },
  {
    icon: recipeIcon,
    activeIcon: recipeIconActive,
    text: 'Recipes',
    isActive: false,
    link: RECIPE
  },
  {
    icon: partnersIcon,
    activeIcon: partnersActive,
    text: 'Partners',
    isActive: false,
    content: [
      {
        link: PARTNERS,
        name: 'Overview'
      },
      {
        link: PRODUCTS_PART,
        name: 'Products'
      }
    ]
  },
  {
    icon: partnersIcon,
    activeIcon: partnersActive,
    text: 'Settings',
    isActive: false,
    link: SETTINGS
  }
];
