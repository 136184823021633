import React, { useEffect, useState } from 'react';
import { Container, Header } from './meal.styles';
import { useDispatch, useSelector } from 'react-redux';
import { get_export, get_meals, get_weekly_preference } from './redux/reducer';
import {
  getExportData,
  getLoading,
  getMealPreference,
  getMeals,
  getPageStats
} from './redux/selector';
import MealCard from './components/mealCard';
import Pagination from '@app/components/pagination';
import SearchContent from './components/search';
import useDebounce from '@app/Hooks/useDebounce';
import LoadingDataUi from '@app/components/loading';
import { Link } from 'react-router-dom';
import { getUser } from '@app/auth/redux/selectors';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');

const fields = [
  'Name',
  'Description',
  'Image',
  'Vegan',
  'Type',
  'Countries',
  'Ingredients',
  'Instructions'
];

const others = ['Ingredients'];

const json2csvParser = new Parser({ fields });
const json2csvParsers = new Parser({ others });

const Meals = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(40);
  const dispatch = useDispatch();
  const meals = useSelector(getMeals);
  const loading = useSelector(getLoading);
  const pageStats = useSelector(getPageStats);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [searchString, setSearchString] = useState('');
  const debouncedTerm = useDebounce(searchString, 500);
  const exportData = useSelector(getExportData);
  const [field, setField] = useState('updated_at');
  const [sortBy, setSortBy] = useState('desc');
  const user = useSelector(getUser);
  const fetching = useSelector(getMealPreference);

  useEffect(() => {
    dispatch(get_export({ page: 1, limit: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      get_meals({
        page: currentPage,
        limit: pageSize,
        selectedFilter,
        searchString: debouncedTerm,
        field,
        sortBy
      })
    );
  }, [
    dispatch,
    currentPage,
    pageSize,
    selectedFilter,
    debouncedTerm,
    field,
    sortBy
  ]);

  const getNewMeals = (page, limit) => {
    dispatch(
      get_meals({ page, limit, selectedFilter, searchString: debouncedTerm })
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewMeals(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewMeals(1, pageSize);
  };

  const handleSelected = (doc) => {
    if (doc === 'All') {
      setSelectedFilter('');
    } else {
      setSelectedFilter(doc);
    }
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const exportMeals = () => {
    let temp = [];
    exportData.forEach((meal) => {
      let obj = {
        Name: meal?.name,
        Description: meal?.description,
        Image: meal?.image,
        Vegan: meal?.vegan ? 'Yes' : 'No',
        Type: meal?.types?.join(', '),
        Countries: meal?.countries?.join(', '),
        Ingredients: meal?.ingredients?.join(', '),
        Instructions: meal?.instructions?.join(', ')
      };
      temp.push(obj);
    });

    const csv = json2csvParser.parse(temp);
    fileDownload(csv, 'Meals.csv');
  };

  const exportIngredients = () => {
    let allIngredients = new Set();

    exportData.forEach((meal) => {
      if (meal.ingredients && Array.isArray(meal.ingredients)) {
        meal.ingredients.forEach((ingredient) => {
          allIngredients.add(ingredient.toLowerCase().trim());
        });
      }
    });

    const temp = [];

    const uniqueIngredients = [...allIngredients];

    uniqueIngredients.forEach((data) => {
      let obj = {
        Ingredients: data
      };
      temp.push(obj);
    });

    // const file = uniqueIngredients.join(', ')

    // const data = {
    //   Ingredients: file
    // }

    const csv = json2csvParsers.parse(temp);
    fileDownload(csv, 'Ingredients.csv');
  };

  const sortField = (doc) => {
    setField(doc);
  };

  const handleSort = (doc) => {
    setSortBy(doc);
  };

  const types = ['All', 'Breakfast', 'Lunch', 'Dinner'];

  const fields = [
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'Date Created',
      value: 'created_at'
    },
    {
      name: 'Date Modified',
      value: 'updated_at'
    }
  ];

  const sort = [
    {
      name: 'Ascending',
      value: 'asc'
    },
    {
      name: 'Descending',
      value: 'desc'
    }
  ];

  const getWeeklyPreference = () => {
    dispatch(get_weekly_preference());
  };

  return (
    <div>
      <Header>
        <div className="title">Meals</div>

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Link to="/meals/create/new">
            <button>Add Meal</button>
          </Link>

          {user?.email === 'richard@useakaani.com' && (
            <button onClick={getWeeklyPreference}>
              {fetching ? 'Getting...' : 'Get Weekly Preference'}
            </button>
          )}
        </div>
      </Header>

      <Container>
        <button onClick={exportIngredients}>Export ingredients</button>
        <div className="content">
          <SearchContent
            types={types}
            handleSelected={handleSelected}
            handleSearch={handleSearch}
            exportMeal={exportMeals}
            field={fields}
            selectedField={field}
            sortField={sortField}
            sort={sort}
            handleSort={handleSort}
            sorted={sortBy}
          />
        </div>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <div>
            <div className="grid">
              {meals.map((meal) => (
                <Link
                  to={`/meals/${meal._id}`}
                  key={meal._id}
                  style={{ textDecoration: 'none' }}
                >
                  <MealCard
                    title={meal.name}
                    type={meal.types}
                    countries={meal.countries}
                  />
                </Link>
              ))}
            </div>

            <div>
              {!loading && (
                <Pagination
                  itemsCount={pageStats.docs}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onChangePageSize={handlePageSizeChange}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Meals;
