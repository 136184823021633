import React from 'react';
import { CardBody, CardContainer } from '../home/containers/stats';
import {
  COUPONS,
  PAYMENTS,
  PREVIOUS_ORDERS,
  STAFF,
  CATEGORIES,
  PRODUCT_GROUPS,
  AKAANI_DRIVE,
  DELIVERY_PARTNERS,
  UNITS,
  MEALS,
  FACTS,
  MEAL_CATEGORIES,
  HEALTH
} from '../ROUTECONSTS';
import couponIcon from '@assets/icons/discount.svg';
import paymentsIcon from '@assets/icons/payments.svg';
import orderhistoryIcon from '@assets/icons/prevHistory.svg';
import partnersIcon from '@assets/icons/partners.svg';
import { MenuIcon } from '@app/main/menu/style';
import { AsgardNavLink } from '@app/globalstyles/asgard';
import { LiaStickyNoteSolid } from 'react-icons/lia';

const links = [
  {
    title: 'Coupons',
    link: COUPONS,
    icon: couponIcon
  },
  {
    title: 'Payments',
    link: PAYMENTS,
    icon: paymentsIcon
  },
  {
    title: 'Previous History',
    link: PREVIOUS_ORDERS,
    icon: orderhistoryIcon
  },
  {
    title: 'Staff',
    link: STAFF,
    icon: partnersIcon
  },
  {
    title: 'Product Categories',
    link: CATEGORIES
  },
  {
    title: 'Product Groups',
    link: PRODUCT_GROUPS
  },
  {
    title: 'Akanni Drive',
    link: AKAANI_DRIVE
  },
  {
    title: 'Delivery Partners',
    link: DELIVERY_PARTNERS
  },
  {
    title: 'Units',
    link: UNITS
  },
  {
    title: 'Meals',
    link: MEALS
  },
  {
    title: 'Meal Categories',
    link: MEAL_CATEGORIES
  },
  {
    title: 'Health Conditions',
    link: HEALTH
  },
  {
    title: 'LU Facts',
    link: FACTS,
    icon: <LiaStickyNoteSolid size="25" color="#828282" />
  }
];

export const Settings = () => {
  return (
    <div style={{ marginTop: 30 }}>
      <CardContainer>
        {links.map((link, idx) => (
          <CardBody key={idx}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                gap: '10px',
                flex: 1,
                height: '100%',
                paddingLeft: '16px'
              }}
            >
              {typeof link.icon !== 'string' ? (
                link.icon
              ) : (
                <MenuIcon src={link.icon} />
              )}

              <AsgardNavLink key={`${idx}`} to={link.link}>
                {link.title}
              </AsgardNavLink>
            </div>
          </CardBody>
        ))}
      </CardContainer>
    </div>
  );
};

export default Settings;
