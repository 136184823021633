import TableFeatures from '@app/components/tablefeatures';
import React, { useEffect, useState } from 'react';
import { CustomersContainer } from './style';
import {
  getCustomers,
  getExportData,
  getLoading,
  getPageStats
} from './redux/selectors';
import CustomerTable from './table';
import { FormsModal } from '@app/globalstyles/asgard';
import AddProductForm from './addcustomer';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@app/components/pagination';
import { get_customers, get_export } from './redux/reducer';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');
const fields = ['Name', 'Email', 'Phone Number'];

const json2csvParser = new Parser({ fields });

function CustomerPage() {
  const loading = useSelector(getLoading);
  const customersFromReducer = useSelector(getCustomers);
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customers, setCustomers] = useState([]);
  const dispatch = useDispatch();
  const pageStats = useSelector(getPageStats);
  const [searchString, setSearchString] = useState('');
  const exportData = useSelector(getExportData);

  useEffect(() => {
    dispatch(get_export({ page: 1, limit: 1000 }));
  }, [dispatch]);

  const handleChange = (value) => {
    setSearchString(value);
  };
  const getNewCustomers = (page, limit) => {
    dispatch(get_customers({ page, limit }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewCustomers(page, pageSize);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(size));
    setCurrentPage(1);
    getNewCustomers(1, pageSize);
  };

  useEffect(() => {
    setCustomers(customersFromReducer);
  }, [customersFromReducer]);

  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };

  const download = () => {
    let temp = [];
    exportData.forEach((user) => {
      let obj = {
        Name: user?.first_name + ' ' + user?.last_name,
        Email: user?.email,
        'Phone Number': user?.phone
      };
      temp.push(obj);
    });
    const csv = json2csvParser.parse(temp);
    fileDownload(csv, 'Customers.csv');
  };

  return (
    <div>
      <CustomersContainer>
        <TableFeatures
          onSearch={handleChange}
          title="Customers"
          createBtnLabel={'Create Customer'}
          addbtn={false}
          onCreateBtn={handleShowProductForm}
          download={download}
          exportBtn={false}
        />
        {showForm && (
          <FormsModal show={showForm}>
            <AddProductForm closeform={handleShowProductForm} />
          </FormsModal>
        )}
        <CustomerTable
          customers={customers}
          currentPage={currentPage}
          pageSize={pageSize}
          searchString={searchString}
        />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </CustomersContainer>
    </div>
  );
}

export default CustomerPage;
